import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c(VTreeview,{attrs:{"active":_vm.active,"items":_vm.items,"load-children":_vm.fetchUsers,"open":_vm.open,"activatable":"","color":"success","open-on-click":"","transition":""},on:{"update:active":function($event){_vm.active=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [(!item.children)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")]):_vm._e()]}}])})],1),_c(VCol,{attrs:{"sm":"1","cols":"12"}},[_c(VDivider,{attrs:{"vertical":_vm.$vuetify.breakpoint.name === 'xs' ? false : true}})],1),_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"6"}},[_c(VScrollYTransition,{attrs:{"mode":"out-in"}},[(!_vm.selected)?_c('div',{staticClass:"title font-weight-light"},[_vm._v(" Select a User ")]):_c(VCard,{key:_vm.selected.id,staticClass:"pt-6 mx-auto",attrs:{"flat":"","max-width":"400"}},[_c(VCardText,{staticClass:"text-center"},[(_vm.avatar)?_c(VAvatar,{staticClass:"mb-6",attrs:{"size":"88","rounded":""}},[_c(VImg,{attrs:{"src":require(("@/assets/images/avatars/" + _vm.avatar))}})],1):_vm._e(),_c('h3',{staticClass:"headline mb-2"},[_vm._v(" "+_vm._s(_vm.selected.name)+" ")]),_c('div',{staticClass:"blue--text mb-2"},[_vm._v(" "+_vm._s(_vm.selected.email)+" ")]),_c('div',{staticClass:"blue--text subheading font-weight-bold"},[_vm._v(" "+_vm._s(_vm.selected.username)+" ")])],1),_c(VDivider),_c(VRow,{staticClass:"text-left",attrs:{"tag":"v-card-text"}},[_c(VCol,{staticClass:"text-right me-4 mb-2",attrs:{"tag":"strong","cols":"5"}},[_vm._v(" Company: ")]),_c(VCol,[_vm._v(_vm._s(_vm.selected.company.name))]),_c(VCol,{staticClass:"text-right me-4 mb-2",attrs:{"tag":"strong","cols":"5"}},[_vm._v(" Website: ")]),_c(VCol,[_c('a',{attrs:{"href":("//" + (_vm.selected.website)),"target":"_blank","rel":"nofollow"}},[_vm._v(_vm._s(_vm.selected.website))])]),_c(VCol,{staticClass:"text-right me-4 mb-2",attrs:{"tag":"strong","cols":"5"}},[_vm._v(" Phone: ")]),_c(VCol,[_vm._v(_vm._s(_vm.selected.phone))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }