import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollXTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c(VCardText,[_c(VTreeview,{attrs:{"load-children":_vm.fetch,"items":_vm.items,"selected-color":"primary","open-on-click":"","selectable":"","return-object":"","expand-icon":_vm.icons.mdiChevronDown,"on-icon":_vm.icons.mdiBookmark,"off-icon":_vm.icons.mdiBookmarkOutline,"indeterminate-icon":_vm.icons.mdiBookmarkMinus},model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}})],1)],1),_c(VCol,{attrs:{"sm":"1","cols":"12"}},[_c(VDivider,{attrs:{"vertical":_vm.$vuetify.breakpoint.name === 'xs' ? false : true}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VCardText,[(_vm.tree.length === 0)?_c('div',{key:"title",staticClass:"title font-weight-light pa-4 text-center"},[_vm._v(" Select your favorite breweries ")]):_vm._e(),_c(VScrollXTransition,{attrs:{"group":"","hide-on-leave":""}},_vm._l((_vm.tree),function(selection,i){return _c(VChip,{key:i,staticClass:"v-chip-light-bg secondary--text ma-1",attrs:{"color":"secondary","dark":"","small":""}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTimerSandEmpty)+" ")]),_vm._v(" "+_vm._s(selection.name)+" ")],1)}),1)],1)],1)],1),_c(VCardActions,{staticClass:"pb-0"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.tree = []}}},[_vm._v(" Reset ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"success","depressed":""}},[_vm._v(" Save "),_c(VIcon,{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiContentSaveOutline)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }